import React from "react";
import './Main.css';
import AboboraTriste from './image.png'

const Main = () => {
    return (
        <section className="relative py-20 md:py-32 overflow-hidden" style={{ marginTop: '20px'}}>
            <div className="relative container px-4 mx-auto">
                <div className="max-w-5xl mx-auto">
                    <img
                        className="block mb-4 mx-auto"
                        src=""
                        alt=""
                    />
                    <div className="flex mb-8 items-end justify-center">
                        <span className="text-9xl sm:text-11xl font-bold text-gray-900">4</span>
                        <img style={{ width: "140px" }}
                            className="block mx-4"
                            src={AboboraTriste}
                            alt=""
                        />
                        <span className="text-9xl sm:text-11xl font-bold text-gray-900">4</span>
                    </div>
                    <h2 className="text-center text-4xl sm:text-5xl font-bold text-gray-900 mb-6">
                        Ops! Não consigo encontrar essa página
                    </h2>
                    <p className="max-w-lg mx-auto text-center text-xl font-semibold text-gray-500 mb-12 md:mb-20">
                        Desculpe, a página que você procura não existe ou foi movida. Tente
                        pesquisando em nosso site
                    </p>
                    <div className="text-center">
                        <a
                            className="relative group inline-block py-3 px-5 text-center text-sm font-semibold text-orange-50 bg-orange-900 rounded-full overflow-hidden"
                            href="/"
                        >
                            <div className="absolute top-0 right-full w-full h-full bg-gray-900 transform group-hover:translate-x-full group-hover:scale-102 transition duration-500" />
                            <span className="relative">Voltar ao inicio</span>
                        </a>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Main;