// plansData.js
export const plansData = [
  {
    name: 'PLANO 1',
    priceMonthly: 'R$29,90',
    priceYearly: 'R$358,80',
    description: 'No credit card required',
    link: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/servidor-vps-amd-epyc-7502p-europa/plano-1',
    features: [
      'Dual Xeon E5-2680 v4',
      '2vCORE',
      '2GB DDR4',
      '20GB NVMe',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
  {
    name: 'PLANO 2',
    priceMonthly: 'R$59,90',
    priceYearly: 'R$718,80',
    description: 'No credit card required',
    link: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/servidor-vps-amd-epyc-7502p-europa/plano-2-1', 
    features: [
      'Dual Xeon E5-2680 v4',
      '2vCORE',
      '4GB DDR4',
      '30GB NVMe',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
  {
    name: 'PLANO 3',
    priceMonthly: 'R$109,90',
    priceYearly: 'R$1.318,80',
    description: 'No credit card required',
    link: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/servidor-vps-amd-epyc-7502p-europa/plano-3-1',
    features: [
      'Dual Xeon E5-2680 v4',
      '4vCORE',
      '8GB DDR4',
      '60GB NVMe',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
  {
    name: 'Personalizado',
    priceContact: 'Contate-nos',
    description: 'for custom offer',
    link: 'https://discord.tomadahost.cloud/', 
    features: [
      'Dual Xeon E5-2680 v4',
      '6vCORE+',
      '12GB DDR4+',
      '120GB NVMe+',
      '12Tbps Proteção Anti-Ddos',
    ],
  },
];
