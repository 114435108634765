import React, { useState } from 'react';
import './Header.css';
import Logo from './logo.png';
import { toast, ToastContainer } from 'react-toastify'; // Importe o toast e o ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Importe o CSS padrão do toast

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  const notificationId = "dedicatedServerError";

  const showUnavailableNotification = () => {
    if (!toast.isActive(notificationId)) { 
      toast.error('A página de Servidores Dedicados está indisponível no momento.', {
        toastId: notificationId,
        position: "bottom-left",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <header>
      <section>
        <nav className="relative py-6 z-50">
          <div className="container px-4 mx-auto">
            <div className="flex items-center relative">
              <a className="inline-block text-lg font-bold" href="/">
                <img
                  className="h-10"
                  src={Logo}
                  style={{ filter: 'invert(1)', width: '100%', height: '60px' }}
                  alt="Logo"
                />
              </a>
              <div className="xl:hidden ml-auto">
                <button
                  onClick={toggleMobileNav}
                  className="flex w-12 h-12 items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-md transition duration-200"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 12H21"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 6H21"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 18H21"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <ul className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 hidden xl:flex lg:w-auto lg:space-x-12">
                <li>
                  <a className="inline-block text-sm text-slate-600 font-medium" href="/minecraft">
                    Minecraft
                  </a>
                </li>
                <li>
                  <a className="inline-block text-sm text-slate-600 font-medium" href="/vps">
                    Servidores VPS
                  </a>
                </li>
                <li>
                  {/* Substitua o href por # e adicione o evento de clique */}
                  <a
                    className="inline-block text-sm text-slate-600 font-medium"
                    href="#"
                    onClick={showUnavailableNotification} // Notificação ao clicar
                  >
                    Servidores Dedicados
                  </a>
                </li>
                <li className="group relative">
                  <a className="inline-block text-sm text-slate-600 font-medium" href="#">
                    Outros
                  </a>
                  <div className="hidden group-hover:block absolute top-full left-0 min-w-max max-w-xs p-4 z-50">
                    <div className="-mb-2 ml-8 w-4 h-4 rounded-sm bg-white border-l border-t border-gray-200 transform rotate-45"></div>
                    <div className="w-full max-w-xs bg-white border border-gray-100 rounded-3xl pt-4 pb-4 px-4">
                      <div className="pb-3 mb-3 border-b border-gray-100">
                        <a
                          className="block py-3 px-4 text-sm text-gray-900 hover:bg-[#f5f5f5] rounded-lg"
                          href="/termos.pdf" target="_blank"
                        >
                          Termos de Uso
                        </a>
                        <a
                          className="block py-3 px-4 text-sm text-gray-900 hover:bg-[#f5f5f5] rounded-lg"
                          href="/politica.pdf" target="_blank"
                        >
                          Politica de Privacidade
                        </a>
                      </div>
                      <div className="pb-3 mb-3 border-gray-100">
                        <a
                          className="flex mb-2 items-center py-3 px-4 text-sm text-gray-900 hover:bg-[#f5f5f5] rounded-lg"
                          href="https://status.tomadahost.cloud/"
                        >
                          <img src="saturn-assets/images/headers/icon-download.svg" alt="" />
                          <span className="ml-3">
                            <i className="fa-solid fa-cloud-binary"></i> Status dos Serviços
                          </span>
                        </a>
                        <a
                          className="flex mb-2 items-center py-3 px-4 text-sm text-gray-900 hover:bg-[#f5f5f5] rounded-lg"
                          href="cliente.tomadahost.cloud/index.php?rp=/knowledgebase"
                        >
                          <img src="saturn-assets/images/headers/icon-slack.svg" alt="" />
                          <span className="ml-3">
                            <i className="fa-duotone fa-solid fa-book"></i> Base de Conhecimento
                          </span>
                        </a>
                        <a
                          className="flex mb-2 items-center py-3 px-4 text-sm text-gray-900 hover:bg-[#f5f5f5] rounded-lg"
                          href="/datacenter"
                        >
                          <img src="saturn-assets/images/headers/icon-help.svg" alt="" />
                          <span className="ml-3">
                            <i className="fa-solid fa-building-memo"></i> Datacenter
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="hidden xl:block ml-auto">
                <div className="flex items-center">
                  <a className="inline-block mr-9 text-sm font-semibold text-slate-600" href="https://financeiro.tomadahost.cloud/">
                    Área do Cliente <i className="fa-duotone fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className={`${mobileNavOpen ? 'block' : 'hidden'} fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50`}>
          <div onClick={toggleMobileNav} className="fixed inset-0 bg-gray-800 opacity-25"></div>
          <nav className="relative flex flex-col py-6 px-10 w-full h-full bg-white border-r overflow-y-auto">
            <div className="flex items-center mb-16">
              <a className="mr-auto text-2xl font-medium leading-none" href="/">
                <img
                  className="h-10"
                  src={Logo}
                  style={{ filter: 'invert(1)', width: '100%', height: '60px' }}
                  alt="Logo"
                />
              </a>
              <button onClick={toggleMobileNav}>
                <svg
                  className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div>
              <ul className="mb-2">
                <li>
                  <a className="block py-4 px-5 text-gray-900 hover:bg-[#f5f5f5] rounded-lg" href="/minecraft">
                    Minecraft
                  </a>
                </li>
                <li>
                  <a className="block py-4 px-5 text-gray-900 hover:bg-[#f5f5f5] rounded-lg" href="/vps">
                    Servidores VPS
                  </a>
                </li>
                <li>
                  <a
                    className="block py-4 px-5 text-gray-900 hover:bg-[#f5f5f5] rounded-lg"
                    href="#"
                    onClick={showUnavailableNotification} 
                  >
                    Servidores Dedicados
                  </a>
                </li>
              </ul>
              <div className="flex px-6 mb-16 items-center">
                <a className="inline-block mr-9 text-sm font-semibold text-slate-600" href="https://financeiro.tomadahost.cloud/">
                  Área do Cliente
                </a>
              </div>
            </div>
          </nav>
        </div>
        {/* Adicione o ToastContainer para as notificações */}
        <ToastContainer />
      </section>
    </header>
  );
};

export default Header;
