import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Inicio from "./Paginas/Inicio"
import Data from "./Paginas/Datacenter"
import NotFound from "./Paginas/NotFound";
import Minecraft from "./Paginas/Minecraft";
import VPS from "./Paginas/VPS";

const AppRoutes = () => {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/minecraft" element={<Minecraft />} />
          <Route path="/datacenter" element={<Data />} />
          <Route path="/vps" element={<VPS />} />
        
        </Routes>
      </Router>
    );
  };
  
  export default AppRoutes;