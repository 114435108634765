import React from "react";
import './Footer.css';
import Logo from './logo.png';

const Footer = () => {
    return (
        <footer className="pt-24 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="pb-20 border-b">
                    <div className="flex flex-wrap -m-8" style={{ marginLeft: "25px"}}>
                        <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
                            <h3 className="mb-6 font-semibold leading-normal">Serviços</h3>
                            <ul>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="/minecraft"
                                    >
                                        Hospedagem Minecraft
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="/vps"
                                    >
                                        Servidores VPS
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="/*"
                                    >
                                        Servidores Dedicados
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
                            <h3 className="mb-6 font-semibold leading-normal">Páginas</h3>
                            <ul>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="https://status.tomadahost.cloud/"
                                    >
                                        Status
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="https://financeiro.tomadahost.cloud/index.php?rp=/knowledgebase"
                                    >
                                        Base de conhecimento
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="https://br.trustpilot.com/review/tomadahost.cloud"
                                    >
                                        Trust Pilot
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
                            <h3 className="mb-6 font-semibold leading-normal">Legal</h3>
                            <ul>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="/termos.pdf"
                                        target="_blank"
                                    >
                                        Termos de Serviço
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="/politica.pdf"
                                        target="_blank"
                                    >
                                        Politica de Privacidade
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="/datacenter"
                                    >
                                        Sobre Nós
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-2/12 p-8">
                            <h3 className="mb-6 font-semibold leading-normal">Entre em Contato</h3>
                            <ul>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="https://discord.tomadahost.cloud/"
                                    >
                                        Discord
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                        href="https://wa.me/556296192903?text=Ol%C3%A1,%20tomadahost!"
                                    >
                                        Whatsapp
                                    </a>
                                </li>
                                <li className="mb-3.5">
                                    <a
                                        className="text-gray-600 hover:text-gray-700 font-medium leading-relaxed"
                                    >
                                        Indisponivel
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-4/12 p-8">
                            <div className="lg:max-w-sm">
                                <h3 className="mb-6 font-semibold leading-normal">Tomadahost Hospedagem e Jogos LTDA</h3>
                                <p className="mb-5 font-sans text-gray-600 leading-relaxed">
                                    Tomadahost é uma empresa que está localizada no município de São Paulo/SP e cuja data de abertura é 02/02/2024, estando atualmente com sua situação cadastral Ativa na Receita Federal. Sua atividade econômica principal é "exploração de jogos eletrônicos recreativos", cujo CNPJ é 53.770.380/0001-00.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-between items-center py-6 -m-4">
                    <div className="w-auto p-4">
                        <a href="#">
                            <img src={Logo} alt="" style={{ width: "200px", filter: 'invert(1)' }} />
                        </a>
                    </div>
                    <div className="w-auto p-4">
                        <p className="text-sm text-gray-600 font-medium">
                            Copyright © 2024 TomadaHost. All Rights Reserved
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    );
};

export default Footer;