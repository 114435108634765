// PricingSection.js
import React, { useState } from 'react';
import { plansData } from './Planos';

const PricingSection = () => {
  const [toggle, setToggle] = useState(false);



  return (
    <section className="pt-24 pb-32 bg-blueGray-50 overflow-hidden">
      <div className="container px-4 mx-auto">
        <h2 className="mb-6 text-6xl md:text-8xl xl:text-10xl font-bold font-heading tracking-px-n leading-none">
          Rápido, Facil, Protegido!
        </h2>
        <div className="mb-16 flex flex-wrap justify-between -m-4">
          <div className="w-auto p-4">
            <div className="md:max-w-md">
              <p className="text-lg text-gray-900 font-medium leading-relaxed">
                As melhores VPS para o seu servidor, trabalho ou armazenamento de dados!
              </p>
            </div>
          </div>
          <div className="w-auto p-4">
            <div className="inline-flex items-center max-w-max">
              <p className="text-gray-600 font-medium leading-relaxed">Mês</p>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  setToggle(!toggle);
                }}
                className="mx-3"
                href="#"
              >
                <div className="p-0.5 w-9 h-5 bg-indigo-600 rounded-full" style={{ backgroundColor: "#FF7100" }}>
                  <div className="relative">
                    <div
                      className={`absolute left-0 w-4 h-4 bg-white rounded-full transition-all duration-200 ${toggle ? 'left-4' : ''
                        }`}
                    ></div>
                  </div>
                </div>
              </a>
              <p className="text-gray-600 font-medium leading-relaxed">
                <span>Ano </span>
                <span className="text-indigo-600 font-semibold" style={{ color: "#FF7100" }}>(Economize 25%)</span>
              </p>
            </div>
          </div>
        </div>
        <div className="overflow-hidden border border-blueGray-200 rounded-3xl">
          <div className="flex flex-wrap divide-y md:divide-x lg:divide-y-0 divide-blueGray-200">
            {plansData.map((plan, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/4">
                <div
                  className="px-9 pt-8 pb-11 h-full bg-white bg-opacity-90"
                  style={{ backdropFilter: 'blur(46px)' }}
                >
                  <span className="mb-3 inline-block text-sm text-indigo-600 font-semibold uppercase tracking-px leading-snug" style={{ color: "#FF7100" }}>
                    {plan.name}
                  </span>
                  <h3 className="mb-1 text-4xl text-gray-900 font-bold leading-tight">
                    {plan.priceContact || (
                      <>
                        <span>{toggle ? plan.priceYearly : plan.priceMonthly}</span>
                        <span
                          className={`text-gray-400 ${toggle ? 'hidden' : ''
                            }`}
                        >
                          /mês
                        </span>
                        <span
                          className={`text-gray-400 ${toggle ? '' : 'hidden'
                            }`}
                        >
                          /ano
                        </span>
                      </>
                    )}
                  </h3>
                  <p className="mb-8 text-sm text-gray-500 font-medium leading-relaxed">
                    {/* {plan.description} */}
                  </p>
                  <a href={plan.link}>
                    <button
                      className="mb-9 py-4 px-9 w-full font-medium border border-blueGray-300 hover:border-blueGray-400 rounded-xl focus:ring focus:ring-gray-50 bg-white hover:bg-gray-50 transition ease-in-out duration-200"
                      type="button"
                    >
                      Comprar Agora
                    </button>
                  </a>
                  <ul>
                    {plan.features.map((feature, i) => (
                      <li key={i} className="mb-4 flex items-center">
                        <svg
                          className="mr-2"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.16699 10.8333L7.50033 14.1666L15.8337 5.83325"
                            stroke="#FF7100"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <p className="font-semibold leading-normal">{feature}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>

        <>
          <div style={{ marginTop: "60px"}}>
          <p className="mb-4 text-sm text-gray-500 text-center font-medium leading-relaxed">
          Confiável pelo serviço de pagamento seguro
          </p>
          <div className="flex flex-wrap justify-center">
            <div className="w-auto">
              <a href="#">
                <img src="https://static.shuffle.dev/components/preview/238eb578-e531-4cf4-a658-a1ff13c9b896/assets/public/flaro-assets/logos/brands/stripe.svg" alt="" />
              </a>
            </div>
            <div className="w-auto">
              <a href="#">
                <img src="https://static.shuffle.dev/components/preview/238eb578-e531-4cf4-a658-a1ff13c9b896/assets/public/flaro-assets/logos/brands/mastercard.svg" alt="" />
              </a>
            </div>
            <div className="w-auto">
              <img src="https://static.shuffle.dev/components/preview/238eb578-e531-4cf4-a658-a1ff13c9b896/assets/public/flaro-assets/logos/brands/paypal.svg" alt="" />
            </div>
            <div className="w-auto">
              <a href="#">
                <img src="https://static.shuffle.dev/components/preview/238eb578-e531-4cf4-a658-a1ff13c9b896/assets/public/flaro-assets/logos/brands/visa.svg" alt="" />
              </a>
            </div>
            <div className="w-auto">
              <a href="#">
                <img src="https://static.shuffle.dev/components/preview/238eb578-e531-4cf4-a658-a1ff13c9b896/assets/public/flaro-assets/logos/brands/apple-pay.svg" alt="" />
              </a>
            </div>
          </div>
          </div>
        </>

      </div>
    </section>
  );
};

export default PricingSection;
