import React from "react";
import './Main.css';

const Main = () => {
    return (
        <section className="overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="relative mb-32">
                    <div className="hidden lg:block absolute top-0 left-12">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={500}
                            height={500}
                            viewBox="0 0 500 500"
                            fill="none"
                        >
                            <path
                                d="M0.5 498.311V304.054C0.5 136.406 136.406 0.5 304.054 0.5H498.311C498.967 0.5 499.5 1.03289 499.5 1.68919V136.561C499.5 137.217 498.967 137.75 498.311 137.75H342.061C290.59 137.75 239.516 163.123 201.319 201.319C163.123 239.516 137.75 290.59 137.75 342.061V498.311C137.75 498.967 137.217 499.5 136.561 499.5H1.68919C1.03249 499.5 0.5 498.967 0.5 498.311Z"
                                fill="url(#paint0_linear_231_9230)"
                                stroke="#F0F0F0"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_231_9230"
                                    x1={0}
                                    y1={500}
                                    x2={500}
                                    y2={0}
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#F8F8F8" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="hidden lg:block absolute top-0 right-12">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={500}
                            height={500}
                            viewBox="0 0 500 500"
                            fill="none"
                        >
                            <path
                                d="M499.5 1.68919V195.946C499.5 363.594 363.594 499.5 195.946 499.5H1.68919C1.03289 499.5 0.5 498.967 0.5 498.311V363.439C0.5 362.783 1.03289 362.25 1.68919 362.25H157.939C209.41 362.25 260.484 336.877 298.681 298.681C336.877 260.484 362.25 209.41 362.25 157.939V1.68919C362.25 1.03289 362.783 0.5 363.439 0.5H498.311C498.967 0.5 499.5 1.03289 499.5 1.68919Z"
                                fill="url(#paint0_linear_231_9229)"
                                stroke="#F0F0F0"
                            />
                            <defs>
                                <linearGradient
                                    id="paint0_linear_231_9229"
                                    x1="499.998"
                                    y1="2.85805e-05"
                                    x2="8.49831"
                                    y2={500}
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop stopColor="#F8F8F8" />
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <div className="relative z-10">
                        <h1 className="text-center text-5xl lg:text-7xl font-bold max-w-lg lg:max-w-3xl mx-auto pt-14 pb-32">
                            Em uma missão de tornar seu dia a dia melhor!
                        </h1>
                        <div className="border border-gray-200 bg-white rounded-3xl flex flex-wrap">
                            <div className="w-full md:w-1/2 lg:w-1/4 py-8">
                                <div className="md:border-r border-gray-200 px-12">
                                    <p className="text-gray-600 mb-2 text-center">Fundado</p>
                                    <h2 className="text-4xl lg:text-5xl font-semibold text-center">
                                        2024
                                    </h2>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/4 py-8">
                                <div className="lg:border-r border-gray-200 px-12">
                                    <p className="text-gray-600 mb-2 text-center">Recursos Compartilhados</p>
                                    <h2 className="text-4xl lg:text-5xl font-semibold text-center">
                                        6
                                    </h2>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/4 py-8">
                                <div className="md:border-r border-gray-200 px-12">
                                    <p className="text-gray-600 mb-2 text-center">Membros de equipe</p>
                                    <h2 className="text-4xl lg:text-5xl font-semibold text-center">
                                        3
                                    </h2>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 lg:w-1/4 py-8">
                                <div className="px-12">
                                    <p className="text-gray-600 mb-2 text-center">Datacenters</p>
                                    <h2 className="text-4xl lg:text-5xl font-semibold text-center">
                                        3
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="text-4xl lg:text-5xl font-semibold text-center mb-14">
                    Dedicado a infundir alegria na tecnologia!
                </h2>
                <div className="flex flex-wrap mb-32 -mx-4">
                    <div className="w-full lg:w-1/2 p-4">
                        <p className="text-gray-600 text-lg">
                            Somos motivados a transformar o cenario dos games em uma jornada
                            cativante de emoção e descoberta. Nosso objetivo é infundir em
                            cada experiencia tecnologica a essencia da alegria.
                        </p>
                    </div>
                    <div className="w-full lg:w-1/2 p-4">
                        <p className="text-gray-600 text-lg">
                            Guiados pela nossa crença fervorosa no poder da tecnologia, tecemos uma tapecaria de interações dinamicas
                            que combinam perfeitamente tecnologicamente, interatividade e conexões humanas significativas.
                        </p>
                    </div>
                </div>
                <div className="flex flex-wrap mb-32 -mx-8">
                    <div className="w-full lg:w-1/2 px-8">
                        <h2 className="text-3xl lg:text-5xl font-bold mb-20 max-w-xs lg:max-w-lg">
                            Uma empresa com valores
                        </h2>
                        <img
                            className="rounded-3xl mb-8 w-full lg:w-auto"
                            src="https://blog.equinix.com/wp-content/uploads/2021/03/SP4-2-e1616075413899-1024x719.jpg"
                            alt=""
                        />
                        <img
                            className="rounded-3xl mb-8 w-full lg:w-auto"
                            src="https://i.imgur.com/KLGcpjl.jpeg"
                            alt=""
                        />
                    </div>
                    <div className="w-full lg:w-1/2 px-8">
                        <p className="text-gray-600 text-lg mb-6">
                        Diversidade, inclusão e pertencimento são fundamentais para o nosso sucesso. Acreditamos que as melhores soluções ocorrem quando uma pluralidade de origens, experiências e identidades trabalham juntas.
                        </p>
                        <div className="flex flex-col gap-2 mb-14">
                            <div className="flex items-center flex-wrap gap-3">
                                <div className="w-4 h-4 rounded-full bg-orange-500 border border-orange-600 flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.4733 4.8067C12.4114 4.74421 12.3376 4.69461 12.2564 4.66077C12.1752 4.62692 12.088 4.6095 12 4.6095C11.912 4.6095 11.8249 4.62692 11.7436 4.66077C11.6624 4.69461 11.5886 4.74421 11.5267 4.8067L6.56001 9.78003L4.47334 7.6867C4.40899 7.62454 4.33303 7.57566 4.2498 7.54286C4.16656 7.51006 4.07768 7.49397 3.98822 7.49552C3.89877 7.49706 3.8105 7.51622 3.72844 7.55188C3.64639 7.58754 3.57217 7.63902 3.51001 7.70336C3.44785 7.76771 3.39897 7.84367 3.36617 7.92691C3.33337 8.01014 3.31728 8.09903 3.31883 8.18848C3.32038 8.27793 3.33953 8.36621 3.37519 8.44826C3.41085 8.53031 3.46233 8.60454 3.52667 8.6667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3388 6.30362 11.3726C6.38486 11.4065 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4065 6.81639 11.3726C6.89763 11.3388 6.97137 11.2892 7.03334 11.2267L12.4733 5.7867C12.541 5.72427 12.595 5.6485 12.632 5.56417C12.6689 5.47983 12.688 5.38876 12.688 5.2967C12.688 5.20463 12.6689 5.11356 12.632 5.02923C12.595 4.94489 12.541 4.86912 12.4733 4.8067Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <span className="text-lg text-gray-600">Confiável</span>
                            </div>
                            <div className="flex items-center flex-wrap gap-3">
                                <div className="w-4 h-4 rounded-full bg-orange-500 border border-orange-600 flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.4733 4.8067C12.4114 4.74421 12.3376 4.69461 12.2564 4.66077C12.1752 4.62692 12.088 4.6095 12 4.6095C11.912 4.6095 11.8249 4.62692 11.7436 4.66077C11.6624 4.69461 11.5886 4.74421 11.5267 4.8067L6.56001 9.78003L4.47334 7.6867C4.40899 7.62454 4.33303 7.57566 4.2498 7.54286C4.16656 7.51006 4.07768 7.49397 3.98822 7.49552C3.89877 7.49706 3.8105 7.51622 3.72844 7.55188C3.64639 7.58754 3.57217 7.63902 3.51001 7.70336C3.44785 7.76771 3.39897 7.84367 3.36617 7.92691C3.33337 8.01014 3.31728 8.09903 3.31883 8.18848C3.32038 8.27793 3.33953 8.36621 3.37519 8.44826C3.41085 8.53031 3.46233 8.60454 3.52667 8.6667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3388 6.30362 11.3726C6.38486 11.4065 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4065 6.81639 11.3726C6.89763 11.3388 6.97137 11.2892 7.03334 11.2267L12.4733 5.7867C12.541 5.72427 12.595 5.6485 12.632 5.56417C12.6689 5.47983 12.688 5.38876 12.688 5.2967C12.688 5.20463 12.6689 5.11356 12.632 5.02923C12.595 4.94489 12.541 4.86912 12.4733 4.8067Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <span className="text-lg text-gray-600">Seguro</span>
                            </div>
                            <div className="flex items-center flex-wrap gap-3">
                                <div className="w-4 h-4 rounded-full bg-orange-500 border border-orange-600 flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.4733 4.8067C12.4114 4.74421 12.3376 4.69461 12.2564 4.66077C12.1752 4.62692 12.088 4.6095 12 4.6095C11.912 4.6095 11.8249 4.62692 11.7436 4.66077C11.6624 4.69461 11.5886 4.74421 11.5267 4.8067L6.56001 9.78003L4.47334 7.6867C4.40899 7.62454 4.33303 7.57566 4.2498 7.54286C4.16656 7.51006 4.07768 7.49397 3.98822 7.49552C3.89877 7.49706 3.8105 7.51622 3.72844 7.55188C3.64639 7.58754 3.57217 7.63902 3.51001 7.70336C3.44785 7.76771 3.39897 7.84367 3.36617 7.92691C3.33337 8.01014 3.31728 8.09903 3.31883 8.18848C3.32038 8.27793 3.33953 8.36621 3.37519 8.44826C3.41085 8.53031 3.46233 8.60454 3.52667 8.6667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3388 6.30362 11.3726C6.38486 11.4065 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4065 6.81639 11.3726C6.89763 11.3388 6.97137 11.2892 7.03334 11.2267L12.4733 5.7867C12.541 5.72427 12.595 5.6485 12.632 5.56417C12.6689 5.47983 12.688 5.38876 12.688 5.2967C12.688 5.20463 12.6689 5.11356 12.632 5.02923C12.595 4.94489 12.541 4.86912 12.4733 4.8067Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <span className="text-lg text-gray-600">Compassivo</span>
                            </div>
                            <div className="flex items-center flex-wrap gap-3">
                                <div className="w-4 h-4 rounded-full bg-orange-500 border border-orange-600 flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.4733 4.8067C12.4114 4.74421 12.3376 4.69461 12.2564 4.66077C12.1752 4.62692 12.088 4.6095 12 4.6095C11.912 4.6095 11.8249 4.62692 11.7436 4.66077C11.6624 4.69461 11.5886 4.74421 11.5267 4.8067L6.56001 9.78003L4.47334 7.6867C4.40899 7.62454 4.33303 7.57566 4.2498 7.54286C4.16656 7.51006 4.07768 7.49397 3.98822 7.49552C3.89877 7.49706 3.8105 7.51622 3.72844 7.55188C3.64639 7.58754 3.57217 7.63902 3.51001 7.70336C3.44785 7.76771 3.39897 7.84367 3.36617 7.92691C3.33337 8.01014 3.31728 8.09903 3.31883 8.18848C3.32038 8.27793 3.33953 8.36621 3.37519 8.44826C3.41085 8.53031 3.46233 8.60454 3.52667 8.6667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3388 6.30362 11.3726C6.38486 11.4065 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4065 6.81639 11.3726C6.89763 11.3388 6.97137 11.2892 7.03334 11.2267L12.4733 5.7867C12.541 5.72427 12.595 5.6485 12.632 5.56417C12.6689 5.47983 12.688 5.38876 12.688 5.2967C12.688 5.20463 12.6689 5.11356 12.632 5.02923C12.595 4.94489 12.541 4.86912 12.4733 4.8067Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <span className="text-lg text-gray-600">Produtivo</span>
                            </div>
                            <div className="flex items-center flex-wrap gap-3">
                                <div className="w-4 h-4 rounded-full bg-orange-500 border border-orange-600 flex items-center justify-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M12.4733 4.8067C12.4114 4.74421 12.3376 4.69461 12.2564 4.66077C12.1752 4.62692 12.088 4.6095 12 4.6095C11.912 4.6095 11.8249 4.62692 11.7436 4.66077C11.6624 4.69461 11.5886 4.74421 11.5267 4.8067L6.56001 9.78003L4.47334 7.6867C4.40899 7.62454 4.33303 7.57566 4.2498 7.54286C4.16656 7.51006 4.07768 7.49397 3.98822 7.49552C3.89877 7.49706 3.8105 7.51622 3.72844 7.55188C3.64639 7.58754 3.57217 7.63902 3.51001 7.70336C3.44785 7.76771 3.39897 7.84367 3.36617 7.92691C3.33337 8.01014 3.31728 8.09903 3.31883 8.18848C3.32038 8.27793 3.33953 8.36621 3.37519 8.44826C3.41085 8.53031 3.46233 8.60454 3.52667 8.6667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3388 6.30362 11.3726C6.38486 11.4065 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4065 6.81639 11.3726C6.89763 11.3388 6.97137 11.2892 7.03334 11.2267L12.4733 5.7867C12.541 5.72427 12.595 5.6485 12.632 5.56417C12.6689 5.47983 12.688 5.38876 12.688 5.2967C12.688 5.20463 12.6689 5.11356 12.632 5.02923C12.595 4.94489 12.541 4.86912 12.4733 4.8067Z"
                                            fill="white"
                                        />
                                    </svg>
                                </div>
                                <span className="text-lg text-gray-600">Pessoas em primeiro lugar</span>
                            </div>
                        </div>
                        <img
                            className="rounded-3xl w-full lg:w-auto"
                            src="https://www.moneyreport.com.br/wp-content/uploads/2023/05/DataCenter-brdigweek-securitybusiness.jpg"
                            alt=""
                        />
                    </div>
                </div>
                <h2 className="text-4xl font-bold text-center mb-4">
                    Conheça a equipe
                </h2>
                <p className="text-center text-gray-600 mb-4">
                    Um grupo dinâmico de indivíduos unidos pela paixão e experiência
                </p>
                <div className="flex flex-wrap mb-32 -mx-4">
                    <div className="w-full lg:w-1/3 p-4">
                        <div
                            className="relative bg-no-repeat bg-cover rounded-3xl w-full max-w-sm mx-auto"
                            style={{
                                height: 440,
                                backgroundImage:
                                    'url("https://i.imgur.com/ozWSuH1.jpeg")'
                            }}
                        >
                            <div className="absolute bottom-0 left-0 bg-white rounded-tr-3xl rounded-bl-3xl px-6 py-3">
                                <h2 className="text-2xl font-bold mb-2">
                                    Marino Landell
                                </h2>
                                <p className="text-sm text-gray-600">Fundador &amp; Diretor</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 p-4">
                        <div
                            className="relative bg-no-repeat bg-cover rounded-3xl w-full max-w-sm mx-auto"
                            style={{
                                height: 440,
                                backgroundImage:
                                    'url("https://static.shuffle.dev/components/preview/0066bef8-104a-41d9-bd92-894baaa0dfce/assets/public/solstice-assets/images/about/men-picture.png")'
                            }}
                        >
                            <div className="absolute bottom-0 left-0 bg-white rounded-tr-3xl rounded-bl-3xl px-6 py-3">
                                <h2 className="text-2xl font-bold mb-2">Erick Campoy</h2>
                                <p className="text-sm text-gray-600">Diretor de Tecnologia</p>
                            </div>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 p-4">
                        <div
                            className="relative bg-no-repeat bg-cover rounded-3xl w-full max-w-sm mx-auto"
                            style={{
                                height: 440,
                                backgroundImage:
                                    'url("https://i.imgur.com/Nvq32TI.png")'
                            }}
                        >
                            <div className="absolute bottom-0 left-0 bg-white rounded-tr-3xl rounded-bl-3xl px-6 py-3">
                                <h2 className="text-2xl font-bold mb-2">
                                    Luigi Napoli
                                </h2>
                                <p className="text-sm text-gray-600">Diretor &amp; Operador</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Main;