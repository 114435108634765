import React from "react";
import './Stats.css';


const Stats = () => {
    return (
        <section className="py-24">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full lg:w-1/3 p-4">
                        <div className="rounded-2xl py-6 px-4" style={{ backgroundColor: "#f1f1f1ab" }}>
                            <h2 className="text-5xl lg:text-6xl font-bold text-center mb-4" style={{ color: "#323232"}}>
                                640+
                            </h2>
                            <p className="font-medium text-center">
                                Jogadores em todos servidores
                            </p>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 p-4">
                        <div className="rounded-2xl py-6 px-4" style={{ backgroundColor: "#f1f1f1ab" }}>
                            <h2 className="text-5xl lg:text-6xl font-bold text-center mb-4" style={{ color: "#323232"}}>
                                32+
                            </h2>
                            <p className="font-medium text-center">Produtos Disponiveis</p>
                        </div>
                    </div>
                    <div className="w-full lg:w-1/3 p-4">
                        <div className="rounded-2xl py-6 px-4" style={{ backgroundColor: "#f1f1f1ab" }}>
                            <h2 className="text-5xl lg:text-6xl font-bold text-center mb-4" style={{ color: "#323232"}}>
                                145+
                            </h2>
                            <p className="font-medium text-center">Usuarios Registrados</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Stats;