import React from "react";
import Header from "../Inicio/Header/Header";
import Main from "./Main/Main";
import Footer from "../Inicio/Footer/Footer"

const Data = () => {
    return (
        <>
            <Header />
            <Main />
            <Footer />
        </>
    );
};

export default Data;