import React, { useState } from "react";
import './Main.css';

const Main = () => {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <section className="overflow-hidden relative">
            <div
                className="h-5"
                style={{
                    background: 'linear-gradient(99deg, #FF7100 6.68%, #FFE0A5 49.09%, #FFC96D 93.31%)',
                }}
            ></div>
            <div className="bg-white relative z-10">
                <div className="container px-4 mx-auto">
                    <div className="absolute left-1/2 transform -translate-x-1/2 top-0 w-full">
                        <div className="flex justify-center gap-6">
                            <div>
                                <div
                                    className="hidden xl:block w-80 h-80 rounded-3xl mb-6"
                                    style={{
                                        background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%)',
                                    }}
                                ></div>
                                <div className="hidden xl:block rounded-3xl pt-6 px-12 relative" style={{ background: "rgba(68, 80, 33, 0.73)"}}>
                                    <img src="https://media.forgecdn.net/attachments/370/357/area_render_48.png" alt=""  style={{ width: "350px"}}/>
                                </div>
                            </div>
                            <div
                                className="hidden md:block w-80 h-80 rounded-3xl"
                                style={{
                                    background:
                                        'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, rgba(248, 248, 248, 0.50) 100%)',
                                }}
                            ></div>
                            <div
                                className="hidden md:block w-80 h-80 rounded-3xl"
                                style={{
                                    background:
                                        'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, rgba(248, 248, 248, 0.50) 100%)',
                                }}
                            ></div>
                            <div>
                                <div
                                    className="hidden xl:block w-80 h-80 rounded-3xl mb-32"
                                    style={{
                                        background: 'linear-gradient(180deg, rgba(248, 248, 248, 0.00) 0%, #F8F8F8 100%)',
                                    }}
                                ></div>
                                <div className="hidden xl:block bg-orange-500 rounded-3xl pt-6 px-12 relative" style={{ background: "rgba(241, 196, 92, 0.62)"}}>
                                    <img src="https://images.minecraft-heads.com/render3d/head/7a/7ac81383be2080a1fc0df75a17a1c596.webp" style={{ width: "150px", position: "relative", left: "40px", top: "-20px"}} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative z-10" style={{ height: '550px' }}>
                        <div className="flex justify-center mt-14">
                            <div className="border border-orange-100 rounded-lg shadow-md px-3 py-2 mb-6 flex items-center gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                    <circle cx="5" cy="5" r="5" fill="#FF7100"></circle>
                                </svg>
                                <p className="text-xs">
                                    <span className="font-semibold">39</span>
                                    <span className="text-gray-700"> Servidores Online</span>
                                </p>
                            </div>
                        </div>
                        <h1 className="text-center text-4xl lg:text-7xl font-bold mb-6 max-w-2xl mx-auto" style={{ fontSize: "60px"}}>
                            A melhor hospedagem de Minecraft
                        </h1>
                        <p className="text-center text-lg mb-10 max-w-xl mx-auto">
                            O melhor serviço ao seu alcance! Desfrute de 'tudo' que a TomadaHost faz para oferecer a você uma experiência excepcional!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Main;