import React, { useState, useRef, useEffect } from 'react';
import './Clientes.css';

const Clientes = () => {

    const [slideWidth, setSlideWidth] = useState(0);
    const [activeSlide, setActiveSlide] = useState(0);
    const slideCount = 3;

    const slide1Ref = useRef(null);
    const slide2Ref = useRef(null);
    const slide3Ref = useRef(null);

    useEffect(() => {
        // Adjust slide width based on the active slide
        const adjustSlideWidth = () => {
            const width = activeSlide === 0 ? 0 : activeSlide === 1 ? slide2Ref.current.offsetWidth : slide3Ref.current.offsetWidth;
            setSlideWidth(width * activeSlide);
        };
        adjustSlideWidth();
    }, [activeSlide]);

    const handleSlideChange = (index) => {
        setActiveSlide(index);
    };


    return (
        <section className="relative pt-24 pb-24 lg:pb-20 lg:pt-32 overflow-hidden">
            <img className="absolute top-0 left-0" src="saturn-assets/images/testimonials/blue-light.png" alt="" />
            <img className="absolute top-0 right-0" src="saturn-assets/images/testimonials/orange-light.png" alt="" />
            <div className="relative container px-4 mx-auto">
                <div className="max-w-7xl mx-auto">
                    <div className="max-w-xl mb-24 mx-auto text-center">
                        <span className="inline-block py-1 px-3 mb-4 text-xs font-semibold rounded-full" style={{ color: "#161616", backgroundColor: "#cbcbcb63"}}>
                            DEPOIMENTOS
                        </span>
                        <h1 className="font-heading text-4xl xs:text-5xl font-bold text-gray-900 mb-6">
                            <span>O que nossos clientes dizem sobre</span> <span className="font-serif italic">TomadaHost</span>
                        </h1>
                    </div>
                </div>
            </div>
            <div className="relative" style={{ height: '600px' }}>
                <div className="overflow-hidden h-full">
                    <div className="absolute left-1/2 transform -translate-x-1/2">
                        <div
                            style={{ transform: `translateX(-${slideWidth}px)` }}
                            className="flex transition-transform duration-500 ease-in-out -m-5"
                        >
                            {/* Slide 1 */}
                            <div x-ref="slide1" className="flex-shrink-0 h-full w-full p-5">
                                <div className="flex mb-8 justify-center">
                                    {/* Testemunhos existentes do slide 1 */}
                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/404594113183481856/8055f97ffa26ee7b61d9a2dc779ddf55.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        nandoo_s
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                                Suporte super atencioso e rápido. Sanou todas as minhas dúvidas e me ajudou no que precisava
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/835353807843622912/bde51b3a457ccbd3f537f370f26c96f1.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        Spartinha
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                                Atendimento perfeito.  respondeu a todas minhas dúvidas equipe perfeita e atenciosa
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/386228139661197313/dc1a633d1d9840402eb7c230fa6200c8.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        Ishiguro
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                                Suporte e atendimento excepcional, sempre respondem rápido, apesar de ainda serem uma equipe "pequena" e uma host nova! A "máquina" tem um ótimo desempenho também, e apesar de ter tido um problema, ele foi resolvido relativamente rápido! e a cada dia percebo cada vez mais que fiz certo em escolher essa host para depositar minha confiança e dinheiro.
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/943592833837854740/792275ec11cf7555c53ed814d0cd20a0.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        Joanini_
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                            Atendimento TOP, Host de qualidade, Sempre que preciso de atendimento eles resolvem o mais rapido possivel,  e o Preço é Bom.
                                            Nota 10/10. RECOMENDO 100%
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Adicione os outros testemunhos */}
                                </div>

                                {/* NOVOS TESTEMUNHOS ABAIXO DO SLIDE 1 */}
                                <div className="flex mb-15 justify-center">
                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/1180880998200791062/2c73bc6b48f0f98acf9e205c73fe7aa9.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        zNisyLofi
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                                Atendimento excelente, suprimiu todas minhas expectativas, excelente host, excelente equipe e soluções quase que imediatas, além do preço excelente para cada tipo de servidor.
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Adicione mais testemunhos abaixo conforme necessário */}
                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/533382842793590795/8895bb1d37eb2303e41ff2e964a3e60f.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        Itachi
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                                Atendimento otimo 10\10, host incrivel , sem lag algum. Recomendo Muito
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full max-w-sm flex-shrink-0 mr-8">
                                        <div className="p-6 bg-white rounded-4xl shadow-lg">
                                            <div className="flex mb-6 items-center">
                                                <img
                                                    className="block w-15 h-15 rounded-full"
                                                    src="https://cdn.discordapp.com/avatars/1089437462213971978/157e517cdbf371a47aaead44675714a3.png?size=2048"
                                                    alt=""
                                                />
                                                <div className="ml-5">
                                                    <span className="block text-xl font-semibold leading-none">
                                                        lionelbr7
                                                    </span>
                                                </div>
                                            </div>
                                            <p className="text-sm text-gray-900 mb-6">
                                            Foi super atencioso e foi rápido e eficiente no atendimento e ainda me ajudou na minha primeira compra da host recomendo muito.
                                            </p>
                                            <div className="flex items-center">
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                                <img className="block mr-1.5" src="saturn-assets/images/testimonials/star.svg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

};

export default Clientes;
