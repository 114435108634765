import React from "react";
import Debian from './sistemas-logos/debian.png'
import Ubuntu from './sistemas-logos/ubuntu.png'
import Windows from './sistemas-logos/windows.png'
import OpenSUSU from './sistemas-logos/opensusu.png'


const Sistemas = () => {
    return (
        <section className="pt-36 pb-28 bg-blueGray-100 overflow-hidden">
            <div className="flex justify-center text-center" style={{ flexDirection: "column" }}>
            <h2 style={{ fontWeight: "600", fontSize: "34px"}}>Sistemas operacionais</h2>
            <p style={{ fontWeight: "400", fontSize: "24px", marginBottom: "40px"}}>Veja abaixo um pouco sobre os nossos sistemas operacionais.</p>
            </div>
            <div className="container px-4 mx-auto">
                <div className="flex flex-wrap justify-center">
                    <div className="w-auto">
                        <img src={Debian} alt="" />
                    </div>
                    <div className="w-auto">
                        <img src={Ubuntu} alt="" />
                    </div>
                    <div className="w-auto">
                        <img src={Windows} alt="" />
                    </div>
                    <div className="w-auto">
                        <img src={OpenSUSU} alt="" />
                    </div>
                </div>
            </div>
        </section>

    );
};

export default Sistemas;