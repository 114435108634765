import React, { useState } from 'react';
import './Preço.css';

const planData = [
    {
        name: 'Terra',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/24x24/e7cf9c9c36/dirt345345.svg',
        priceMonthly: 25,
        priceYearly: 300,
        includes: [
            { text: '1vCore (100%)', included: true },
            { text: '2GB Ram', included: true },
            { text: '15GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: false },
            { text: 'Plugins para minecraft', included: false },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/terra',
    },
    {
        name: 'Areia',
        isPopular: false,
        img: 'https://storyblok.shockbyte.com/f/296405/97x96/e49820d3eb/sand765754645.svg',
        priceMonthly: 40,
        priceYearly: 480,
        includes: [
            { text: '2vCore (200%)', included: true },
            { text: '4GB Ram', included: true },
            { text: '25GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: false },
            { text: 'Plugins para minecraft', included: false },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/areia',
    },
    {
        name: 'Pedra (Popular)',
        isPopular: true,
        img: 'https://images.minecraft-heads.com/render3d/head/3f/3fbbed193946da3b7cba953023b9e1a9.webp',
        priceMonthly: 55,
        priceYearly: 660,
        includes: [
            { text: '2vCore (200%)', included: true },
            { text: '6GB Ram', included: true },
            { text: '35GB Armazenamento', included: true },
            { text: 'Proteção Anti-Ddos', included: true },
            { text: 'Sub-dominios incluso', included: true },
            { text: 'Backups incluso', included: true },
            { text: 'Suporte a Java ou Bedrock', included: true },
            { text: 'Suporte a Mods e Plugins', included: true },
            { text: 'Suporte a GeyserMC e Proxy', included: true },
            { text: 'Loja para seu servidor', included: false },
            { text: 'Servidores adicionais', included: false },
            { text: 'Plugins para minecraft', included: false },
        ],
        redirectLink: 'https://financeiro.tomadahost.cloud/index.php?rp=/store/hospedagem-minecraft/pedra',
    },
];

const PricingSection = () => {
    const [toggle, setToggle] = useState(false);

    return (
        <section className="relative py-20 overflow-hidden bg-gray-50" style={{ marginTop: '60px' }}>
            <div className="relative container px-4 mx-auto">
                <div className="max-w-2xl lg:max-w-5xl mx-auto mb-24 text-center">
                    <h1 className="font-heading text-4xl sm:text-6xl lg:text-7xl font-bold text-gray-900 mb-8">
                        <span>Escolha um plano para o seu servidor! </span>
                    </h1>
                    <div className="flex items-center justify-center">
                        <div
                            onClick={() => setToggle(!toggle)}
                            className="relative inline-flex p-1 w-12 h-7 items-center justify-end bg-gray-100 rounded-full cursor-pointer"
                        >
                            <span
                                className={`absolute left-1 w-5 h-5 bg-orange-900 rounded-full transition-all duration-500 ${toggle ? 'left-1/2' : 'left-1'
                                    }`}
                            ></span>
                        </div>
                        <span className={`ml-3 text-xl text-gray-900 ${!toggle ? 'block' : 'hidden'}`}>
                            Preço mensal (20%)
                        </span>
                        <span className={`ml-3 text-xl text-gray-900 ${toggle ? 'block' : 'hidden'}`}>
                            Preço anual (20%)
                        </span>
                    </div>
                </div>
                <section class="py-12 py-lg-24 position-relative overflow-hidden">
                    <div class="container">
                        <div class="row">
                            {planData.map((plan, index) => (
                                <div key={index} className="col-12 col-lg-4 mb-8 mb-lg-0">
                                    <div className={`position-relative mw-sm mw-lg-none mx-auto ${plan.isPopular ? 'bg-primary bg-opacity-75' : 'bg-white'} rounded-5`}>
                                        <div className="pt-2 px-2">
                                            <div className={`position-relative pt-12 pb-12 px-6 rounded-5 ${plan.isPopular ? 'bg-primary' : 'bg-light'} overflow-hidden`} style={{ height: '208px' }}>
                                                <img className="position-absolute bottom-0 start-0 w-100" src={plan.isPopular ? "saturn-assets/images/pricing/wave-bg2.svg" : "saturn-assets/images/pricing/wave-bg1.svg"} alt="" />
                                                <div className="position-relative text-center">
                                                    <span className={`d-inline-block py-1 px-5 mb-8 fw-semibold ${plan.isPopular ? 'text-white bg-danger-light bg-opacity-25' : 'text-danger bg-danger-light'} rounded-pill`}>{plan.name}</span>
                                                    <span className={`d-block fs-2 fw-bold ${plan.isPopular ? 'text-white' : ''}`}>R${toggle ? plan.priceYearly : plan.priceMonthly},00</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3 mb-12 position-relative">
                                            <div className="position-absolute start-0 top-50 translate-middle-y w-100">
                                                <div className="w-100 border-primary-light" style={{ borderTop: 0, borderBottom: '8px', borderStyle: 'dotted' }}></div>
                                            </div>
                                            <div className="position-absolute top-0 start-0 ms-n4 translate-middle-y p-4 bg-primary-light rounded-pill"></div>
                                            <div className="position-absolute top-0 end-0 me-n4 translate-middle-y p-4 bg-primary-light rounded-pill"></div>
                                        </div>
                                        <div className="px-6 pb-12">
                                            <ul className="list-unstyled mb-12">
                                                {plan.includes.map((item, idx) => (
                                                    <li key={idx} className="d-flex mb-5 align-items-center">
                                                        <img className="img-fluid" src={item.included ? 'saturn-assets/images/pricing/green-check.svg' : 'saturn-assets/images/pricing/red-check.svg'} alt="" />
                                                        <span className={`ms-3 ${item.included ? (plan.isPopular ? 'text-white' : '') : 'text-secondary'}`}>{item.text}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                            <div className="text-center">
                                                <a className={`btn btn-sm ${plan.isPopular ? 'btn-light text-primary' : 'btn-primary'} rounded-pill`} href={plan.redirectLink}>
                                                    <span>Escolher Plano</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

            </div>
        </section>
    );
};

export default PricingSection;
